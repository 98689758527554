import { isObject, isString } from "lodash";
import Resolver from "@olive/oli-sandbox-resolver";
const jsonata = require('jsonata');

enum TaskType {
  data = "data",
  mapper = "mapper",
  api = "api"
}

interface Task {
  type: TaskType,
  label?: string,
  //its auto generated when we don't pass it
  taskRef?: string,
  inputParameters?: any,
  mapping?: Object,
  api?: Object
}

async function recursiveResolving(resolvedOutput, inputParameters) {
  if (isString(inputParameters)) {
    const pattern = /\${(.*?)}/g;
    const match = pattern.exec(inputParameters);
    if (match) {
      const expression = jsonata(match[1]);
      const result = await expression.evaluate(resolvedOutput);
      return result;
    }
    console.error(`Can not resolve string: ${inputParameters}`);
    return {};
  }
  const returnValue = { ...inputParameters }

  for (const key in inputParameters) {
    const value = inputParameters[key];
    if (isObject(value)) {
      const result = await recursiveResolving(resolvedOutput, value)
      returnValue[key] = result;
    }
    else {
      const pattern = /\${(.*?)}/g;
      const match = pattern.exec(value);
      if (match) {
        const expression = jsonata(match[1]);
        const result = await expression.evaluate(resolvedOutput);
        returnValue[key] = result;
      }
    }
  }
  return returnValue;
}

async function fetchFromDataLayer(resolvedOutput, inputParameters, resolveID) {
  const returnValue = await recursiveResolving(resolvedOutput, inputParameters);
  const dataLayerURL = Resolver.resolveForFrontend("https://api.[$oli-sub-domain$]olive-team.com/data-layer/rest");
  const response = await fetch(dataLayerURL,
    {
      method: "POST",
      headers: {
        ['oli-resolve-id']: resolveID
      },
      body: JSON.stringify(returnValue)
    });
  return await response.json();
}

async function resolveMapping(resolvedOutput, inputParameters, mapping, functionManager) {
  const returnValue = await recursiveResolving(resolvedOutput, inputParameters);
  const { type } = mapping;
  switch (type) {
    case "inline":
      const { rawContent } = mapping;
      const evalFunc = eval(rawContent);
      return evalFunc(returnValue);
    case "pilet":
      const { id } = mapping;
      //TODO FBA: function Manager should not bound if props are bound to it
      const piletFunction = functionManager.get(`${id}-unbound`);
      const result = piletFunction(returnValue);
      return result
    default:
      break;
  }
  console.log(mapping);
  return returnValue;
}


const mockedTasks = [{
  "type": TaskType.data,
  "label": "Fetch Model Data",
  "taskRef": "modelData",
  "inputParameters": {
    "type": "model",
    "requestParams": {
      "modelID": "${onNodeClick.id}",
    },
    "origin": "${onNodeClick.origin}"
  }
}, {
  "label": "Transform Data to React Modeller",
  "type": TaskType.mapper,
  "taskRef": "oliveInlineMap",
  "mapping": {
    "type": "inline",
    "rawContent": "modelData=>modelData.id"
  },
  "inputParameters": "${modelData}"
}/* , {
  "type": TaskType.api,
  "api": {
    "name": "openModel",
    "targetCmp": "react-modeller-LHET5YUWM5PDJ",
  },
  "label": "Open Model",
  "inputParameters": "${oliveInlineMap}"
} */]

const TaskQueue = {
  resolve: async (input = {}, tasks: Task[] = mockedTasks, resolveID, functionManager) => {
    const resolvedOutput = { ...input };
    let result = input;
    for (let index = 0; index < tasks.length; index++) {
      const { type, taskRef, inputParameters } = tasks[index];
      switch (type) {
        case TaskType.data:
          result = await fetchFromDataLayer(resolvedOutput, inputParameters, resolveID);
          resolvedOutput[taskRef] = result;
          break;
        case TaskType.mapper:
          result = await resolveMapping(resolvedOutput, inputParameters, tasks[index].mapping, functionManager);
          resolvedOutput[taskRef] = result;
          break;
        case TaskType.api:
          break;
        default:
          break;
      }
    }
    console.log(resolvedOutput)
    return result;
  }

}

export default TaskQueue;