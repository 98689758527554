import React, { useState, useEffect } from "react";

import DataManager from "./data/DataManager";
import MappingManager from "./data/MappingManager";
import ConfigStore from "./data/ConfigStore";
import PiralInstance from "./PiralInstance";
import { Provider } from "react-redux";
import Resolver from "@olive/oli-sandbox-resolver";
import createMonitoring from "./monitoring/RUMProvider";
import FunctionManager from "./FunctionManager";

export default () => {

  // example usage: https://piral.olive-team.com/preview?componentID=%40olive%2Fmodeller.olive-modeller&props=%7B%22text%22%3A%20%22test123%22%7D

  const urlParams = new URL(document.location).searchParams;
  const mode = urlParams.get("mode");
  let configID = urlParams.get("config");
  const componentID = urlParams.get("componentID");
  const urlProps = urlParams.get("props");


  if (!configID && !componentID) {
    return "Error: Need configID or componentID - And a new text";
  }

  const subdomain = Resolver.getSubDomain();

  console.info("subdomain: " + subdomain);
  console.info("mode: " + mode);
  console.info("configID: " + configID);
  console.info("componentID: " + componentID);
  console.info("props: " + urlProps);

  const [layout, setLayout] = useState(null);
  const [isReady, setReady] = useState(false);
  const [data, setData] = useState(null);
  let componentProps = {};

  useEffect(() => {
    if (layout && data) {
      setReady(true);
    }
  }, [isReady, layout]);

  if (urlProps && componentID) {
    const jsonProps = JSON.parse(urlProps);
    componentProps[componentID] = jsonProps;
  }

  if (layout === null && mode === "preview") {
    setLayout("single");
    return;
  }
  if (!data) {
    fetchData(configID, componentID, mode).then((data) => {
      if (layout !== "single") {
        setLayout(data.layout || "single");
      }
      setData(data);
      setReady(true);
    });
    return "Loading...";
  }

  if (!isReady) {
    return "Loading...";
  }

  if (mode === "preview") {
    return <PiralInstance data={data} layout={layout}></PiralInstance>
  }

  const configStore = new ConfigStore(data);
  const functionMgr = new FunctionManager();
  const mappingMgr = new MappingManager({ configStore });
  const dataMgr = new DataManager({ configStore, mappingMgr, functionMgr });

  const monitoring = createMonitoring();
  monitoring.recordPageView(location.pathname);

  return (<Provider store={dataMgr.getStore()}>
    <PiralInstance dataMgr={dataMgr} data={data} layout={layout} functionMgr={functionMgr} monitoring={monitoring}></PiralInstance>
  </Provider>)
}

function fetchData(configID, componentID, mode) {
  let url = Resolver.resolveForFrontend("https://api.[$oli-sub-domain$]olive-team.com/piral/getData?")
  // let url = 'https://g7wnm2bdha.execute-api.eu-central-1.amazonaws.com/dev/getData?';
  url += 'config=' + encodeURIComponent(configID);
  url += '&componentID=' + encodeURIComponent(componentID);
  url += '&mode=' + mode
  return fetch(url).then((res) => { const json = res.json(); return json; });
}

