import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Resolver from "@olive/oli-sandbox-resolver";
//should get rid of baseUrl with usage of sandboxes
export const create = ({
  componentId,
  baseUrl = Resolver.resolveForFrontend("https://api.[$oli-sub-domain$]olive-team.com/data-layer/"),
  inputParameters,
  resolveID
}: {
  componentId: string;
  baseUrl: string;
  inputParameters: any;
  resolveID: string;
}) => {
  const api = createApi({
    reducerPath: componentId,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers) => {
        headers.set('oli-resolve-id', resolveID);
        return headers;
      }
    }),
    endpoints: (builder) => ({
      getDataByType: builder.query({
        query: () => ({
          url: `rest`,
          method: "POST",
          body: inputParameters
        }),
      }),
    }),
  });
  return { api, hooks: { useGetDataByTypeQuery: api.useGetDataByTypeQuery } };
};
