import mockedComponents from "../mockedComponents";

export default class ConfigStore {

  constructor(data) {
    if (!data || !data.config || !data.serviceDefinitions) {
      console.error("Config store did not receive correct data, either config or serviceDefinitions are missing", data);
      throw "Config store did not receive correct data, either config or serviceDefinitions are missing";
    }

    this.config = data.config;
    this.serviceDefinitions = data.serviceDefinitions;
  }

  getConfig() {
    return this.config;
  }

  getContent() {
    return this.config.components;
  }

  getServiceDefinition(serviceID) {
    return this.serviceDefinitions[serviceID];
  }

  getAllServiceDefinitions() {
    return this.serviceDefinitions;
  }

}