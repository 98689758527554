import ConfigStore from "./ConfigStore";
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { find, forEach, isEmpty } from "lodash";
import { create } from "./componentLoadFactory"
import TaskQueue from "./TaskQueue";

export default class DataManager {
  constructor({ configStore, mappingMgr, functionMgr }) {
    this.configStore = configStore;
    this.mappingMgr = mappingMgr;
    this.functionMgr = functionMgr;
    this.hooks = {};
    this.#initReducers();
    this.#createStore();
  }


  #initReducers() {
    // parse all data sources from this.configStore.getConfig() and create an endpoint/slice/api/reducer for each of them
    //generating a slice for all components
    this.reducer = {}
  }

  #generateStoreConfig() {
    const componentWorkflow = this.configStore?.config?.componentWorkflow;
    if (!componentWorkflow) {
      // default object to not throw errors
      return {
        reducer: (state) => state,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      };
    }

    const reducer = {};
    const middleware = [];

    forEach(componentWorkflow, ({ actions }, key) => {
      forEach(actions, (action) => {
        if (action.eventTrigger === "onLoad") {
          const { api, hooks } = create({
            componentId: key,
            dataManager: this,
            inputParameters: action?.tasks && action.tasks[0]?.inputParameters,
            resolveID: componentWorkflow?.root?.resolveID
          });
          reducer[api.reducerPath] = api.reducer;
          this.hooks[key] = hooks;
          middleware.push(api.middleware);
        }
      })

      /*    
            if(!isEmpty(component.metaData.data))
            {
              const {api, hooks} = create({componentId: key, dataManager: this});
              reducer[api.reducerPath] = api.reducer;
              this.hooks[component.id] = hooks;
              middleware.push(api.middleware);
            }
            console.log(component); */
    })

    return {
      reducer, middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware)
    };
  }

  #createStore() {
    //for each API we need to add reducer and a middleweare
    this.store = configureStore(this.#generateStoreConfig())

    //lets see if this is really necessary
    setupListeners(this.store.dispatch)
  }

  /**
   * 
   * @param {dataSourceID} id of the data source in the config
   * @param {opts} 
   * @returns the data in the configured format
   */
  async loadData({ dataSourceID, opts }) {

    return null;
  }

  async resolveAction({ sourceCmp, sourceTrigger, input }) {
    const componentWorkflow = this.configStore?.config?.componentWorkflow;
    if (!componentWorkflow) {
      return input[sourceTrigger];
    }
    const actions = componentWorkflow[sourceCmp]?.actions;
    if (!actions) {
      return input[sourceTrigger];
    }

    const foundAction = find(actions, actionItem => actionItem.eventTrigger === sourceTrigger);
    if (!foundAction) {
      return input[sourceTrigger];
    }

    const { tasks } = foundAction;
    if (!tasks || tasks.length === 0) {
      return input[sourceTrigger];
    }
    const resolveID = componentWorkflow?.root?.resolveID;
    //TODO FBA: can we somehow think of a way to not a dependency in the taskqueue to the function mgr?
    const result = await TaskQueue.resolve(input, tasks, resolveID, this.functionMgr);
    return result;
  }

  getHook(componentID) {
    return this.hooks[componentID];
  }

  /**
   * @returns the data manager store
   */
  getStore() {
    // not 100% sure yet if we can/should expose the store
    return this.store;
  }

}