import React, { useEffect, useState, forwardRef } from 'react';

const ExtensionComponent = forwardRef(({ id, extensionProps, dataMgr, ...other }, ref) => {

  const [componentState, setComponentState] = useState("init");
  const [data, setData] = useState({});

  if (dataMgr && dataMgr.getHook(id)) {
    const { data: retrievedData, error, isLoading } = dataMgr.getHook(id).useGetDataByTypeQuery('treenew');
    if (componentState === "init" && retrievedData) {
      setComponentState("loaded");
      setData(retrievedData);
    }
    if (isLoading) {
      return <div>Loading...</div>;
    }
  }

  const props = { ...extensionProps.props, ...data, ...other };

  return (
    <extensionProps.component ref={ref} {...props}>
      {extensionProps.children}
    </extensionProps.component>
  );
});

export default ExtensionComponent;